@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: 'Gilroy';
  src: url('./fonts/Gilroy-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'GTAlpina';
  src: url('./fonts/GT-Alpina-Fine-Condensed-Thin-Italic-Trial.otf') format('opentype');
}

@font-face {
  font-family: 'Druk-Italic';
  src: url('./fonts/Druk-Medium-Italic.otf') format('opentype');
}



body {
  background-color: #060606;
  color: #F5F5EF;
  font-family: 'Druk-Italic', sans-serif;
}

