.light-bg{
  background-color: #F5F5EF;
  color: #060606;
}

.yellow{
  font-family: 'Druk-Italic';
  color: #FAFF00;
}

.shadow{
  text-shadow: 0px 2.02px 2.408px rgba(250, 255, 0, 0.19), 0px 4.855px 5.786px rgba(250, 255, 0, 0.27), 0px 9.141px 10.894px rgba(250, 255, 0, 0.34), 0px 16.306px 19.433px rgba(250, 255, 0, 0.40), 0px 30.498px 36.347px rgba(250, 255, 0, 0.48), 0px 73px 87px rgba(250, 255, 0, 0.67);
}


.ital{
  font-family: 'GTAlpina';
  color: #AEAEAE;
}

.big-font{
  font-size: 56px;
}

.light-button{
  border: 1px solid #060606;
  padding:12px;
  font-size: 10px;
  cursor: pointer;
  font-family: 'Gilroy';
  letter-spacing: 2px;
  transition: 0.3s;
}

.light-button:hover{
  border: 1px solid #060606;
  padding:12px;
  font-size: 10px;
  cursor: pointer;
  font-family: Gilroy;
  letter-spacing: 2px;
  background-color: #060606;
  color:#F5F5EF;
  scale:1.2;
  transition: 0.3s;
}

.dark-button{
  border: 1px solid #F5F5EF;
  padding:12px;
  font-size: 10px;
  cursor: pointer;
  font-family: Gilroy;
  letter-spacing: 2px;
  transition: 0.3s;
}

.dark-button:hover{
  border: 1px solid #F5F5EF;
  padding:12px;
  font-size: 10px;
  cursor: pointer;
  font-family: Gilroy;
  letter-spacing: 2px;
  background-color: #F5F5EF;
  color:#060606;
  scale:1.2;
  transition: 0.3s;
}

.text-font{
  font-family: Gilroy;
}

.video-background-container {
  position: relative;
  overflow: hidden;
}

.background-video {
  position: absolute;
  width: 100%;
  left: 50%;
  top: 50%;
  height: 100%;
  object-fit: cover;
  transform: translate(-50%, -50%);
  z-index: -1; /* Keep the video behind the content */
}

.content {
  position: relative;
  z-index: 2; /* Ensure content is above the video */
  /* Additional styling for your content if needed */
}

.group {
  position: relative; /* Ensures proper stacking of elements */
  overflow: hidden; /* Ensures the description does not show outside the container */
}

.description {
  max-height: 0; /* Start with the description hidden */
  overflow: hidden;
  transition: max-height 0.5s ease-in-out; /* Smooth transition for sliding effect */
}

.group:hover .description {
  max-height: 500px; /* Adjust as needed based on your content size */
  transition: max-height 0.5s ease-in-out;
}

.grey-icon {
  filter: grayscale(100%);
  transition: filter 0.3s;
}

.grey-icon:hover {
  filter: grayscale(0);
}

.gallery {
  /* Hide the scrollbar */
  overflow: hidden;
  max-width: 100%;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.gallery::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Opera */
}

.image-container img {
  display: inline-block;
  white-space: normal;
}

.gradient-border {
  background: transparent; /* Set the background of the div to transparent */
  position: relative; /* Needed to position the pseudo-element */
  z-index: 1; /* Ensures the content of the div is above the pseudo-element */
}

.gray-bg{
  background-color: #212121;
}

@keyframes rotate {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(360deg);
  }
}

.rotate {
  animation: rotate 2s linear infinite;
}


@keyframes pulse {
  0% {
    transform: scale(0.8);
    opacity: 0.5;
  }
  50% {
    transform: scale(1);
    opacity: 1.5;
  }
  100% {
    transform: scale(0.8);
    opacity: 0.5;
  }
}

.loader {
  width: 50px;
  height: 50px;
  background-color: #FAFF00;
  border-radius: 50%;
  animation: pulse 1s infinite ease-in-out;
}
